/* 
html, body {
    width: 100%;
    height: 100%;
}
html, body, body > div {
height: 100%;
overflow: hidden;
} */

/* .overflow {
width: 100%;
height: 100%;
overflow: hidden;
} */
.overflow {
    /* display:grid; */
    align-items: center;
    justify-content: center;
}
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.bg-navigation-dark {
    background: #11998e; 
    background: -webkit-linear-gradient(to right, #38ef7d, #11998e);  
    background: linear-gradient(to right, #38ef7d, #11998e); 

    /* max-width: 300px; */
    margin-top: 20px;
    border-radius: 40px;
    padding: 20px;
    position: fixed;
    bottom: 0;
    margin-bottom: 30px;
    z-index: 2;
}

.bg-navigation-light{
    /* background: #0f0c29;  
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29);  */

    background: #8E2DE2;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    margin-top: 20px;
    border-radius: 40px;
    padding: 20px;
    position: fixed;
    bottom: 0;
    margin-bottom: 30px;
    z-index: 2;
}
.navigation-image {
    width: 40px;
    cursor: pointer;
}
.navigation-imageMessage {
    width: 42px;
    transform: translateY(8%);
    cursor: pointer;
}
.margin-navigation {
    margin-right: 10px;
}
/* .navigation-image2 {
    width: 20px;
    cursor: pointer;
} */
.navigation-image:hover {
    transition: 0.3s;
    transform: scale(1.1);
}
.navigation-imageMessage:hover {
    transition: 0.3s;
    transform: translateY(8%) scale(1.1) ;
}
.bg-bodyLight {
    /* background-color: #F5F5F5; */
    background-color: aliceblue;
    min-height:100vh;
    background-size:cover;
    color: black;
    font-family: 'Nunito', sans-serif;
    transition: 1s;
}
.bg-bodyDark {
    background-color: #232b2b;
    min-height:100vh;
    color: #FBFAF5;
    font-family: 'Nunito', sans-serif;
    transition: 1s;
}

/* ScrollBar */
/* width */
::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #232526;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 20px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}
::-webkit-scrollbar:horizontal {
    height: 0;
    width: 0;
    display: none;
}

::-webkit-scrollbar-thumb:horizontal {
    display: none;
}

/* Responsive */
@media(max-width: 576px) {
    .bg-navigation-dark, .bg-navigation-light 
    { max-width: 250px; padding: 15px; transform: translateX(4%); }
    .navigation-image { width: 30px; }
    .navigation-imageMessage { width: 32px; transform: translateY(4%); }
    .navigation-imageMessage:hover { transform: translateY(4%) scale(1.1);}
    .margin-navigation { margin-right:7px; }
    .overflow {
        overflow: visible;
    }
    ::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
    }
}
@media (min-width: 576px) and (max-width: 899px) { }
@media(min-width: 768px) { }
@media(min-width: 1024px) { }
@media(min-width: 1024px) and (height: 600px){ }
@media(min-width: 280px) and (height: 653px){
    .bg-navigation-dark, .bg-navigation-light 
    { transform: translateX(3.4%); }
}
