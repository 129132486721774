html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-height: 100%; */
  /* width: 100%; */
  /* background: #FC5C7D;  
  background: -webkit-linear-gradient(to right, #6A82FB, #FC5C7D); 
  background: linear-gradient(to right, #6A82FB, #FC5C7D); 
   */

  /* background-size: cover; */
  /* width: 100%;
  min-height: 100%; */
  position: relative;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
