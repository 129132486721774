.container-1 {
    /* overflow: scroll; */
    padding-bottom: 40px;
}
.greet-profile {
    font-size: 30px;
    margin-top: -160px;
}
.text-profile {
    font-size: 18px; 
    padding-right: 50px;
    margin-top: -40px;
}
.image-profile {
    width: 160px;
    border-radius: 50%;
    margin-top: -5px;
    right: 0;
}
.rating-text {
    /* text-align: center; */
}
.rating-container1 {
    display: flex;
    align-items: center;
    margin-top: -20px;
    justify-content: center;
}
.rating-container2 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.margin-rating {
    margin-top: -44px;
}
.newest-projectButton {
    display: flex;
    align-items: center;
    justify-content: center;
}
.newest-projectText {
    text-align: center;
    font-size: 28px;
}
.hide {
    display: none;
}
/* Responsive */
@media(max-width: 576px) {
    .rating-text {
        text-align: center;
        font-size: 28px;
    }
    .container-1 {
        padding-bottom: 130px;
    }
    .newest-projectText {
        text-align: center;
        font-size: 28px;
    }
    .text-profile {
        padding-right: 0px;
    }
    .greet-profile {
        margin-top: -100px;
    }
    .overflow {
        overflow: scroll;
    }
}
@media (min-width: 576px) and (max-width: 899px) {  }
@media(min-width: 768px) { 
    /* .project-image { max-width: 200px; display: block; justify-content: center; margin: auto; }
    .judul-project { text-align: center; } */
 }
@media(max-width: 899px) { 
    .image-profile { 
        display: none;
    }
    .greet-profile {
        margin-top: -50px;
    } 
}
@media(min-width: 1024px) { }
@media(min-width: 1024px) and (height: 600px){ }
@media(min-width: 280px) and (height: 653px){ }
@media(min-width: 1253px) and (max-height: 651px) {
    .greet-profile {
        font-size: 30px;
        margin-top: -16px;
    }
    .container-1 {
        padding-bottom: 130px;
    }
    .overflow {
        overflow: scroll;
    }
}
@media (max-height: 910px) {
    .container-1 {
        padding-bottom: 130px;
    }
    .overflow {
        overflow: scroll;
    }
}