@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-height: 100%; */
  /* width: 100%; */
  /* background: #FC5C7D;  
  background: -webkit-linear-gradient(to right, #6A82FB, #FC5C7D); 
  background: linear-gradient(to right, #6A82FB, #FC5C7D); 
   */

  /* background-size: cover; */
  /* width: 100%;
  min-height: 100%; */
  position: relative;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Baru */
html, body {
  width: 100%;
  height: 100%;
}
html, body, body > div {
  height: 100%;
  /* overflow: hidden; */
}
.overflow {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.bg-navigation {
  background: #11998e;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  margin-top: 20px;
  border-radius: 40px;
  /* min-width: 320px;
  max-width: 320px; */
  padding: 20px;
  position: fixed;
  bottom: 0;
  margin-bottom: 30px;
  z-index: 2;
  
}

.bg-navigation-switch {
  background: #0f0c29;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-top: 20px;
  border-radius: 40px;
  /* min-width: 320px;
  max-width: 320px; */
  padding: 20px;
  position: fixed;
  bottom: 0;
  margin-bottom: 30px;
  z-index: 2;
}
.navigation-image {
  width: 40px;
  cursor: pointer;
}
.navigation-image2 {
  width: 20px;
  cursor: pointer;
}
.navigation-image:hover {
  transition: 0.3s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.hide{
  display: none;
}
.bg-bodyLight {
  background-color: #F5F5F5;
  min-height:100vh;
  background-size:cover;
}
.bg-bodyDark {
  background-color: #232b2b;
 /* height: 97.94%; */
  min-height:100vh;
  /* background-size:cover; */
  /* width: 100%; */
  color: #FBFAF5;
  /* position: relative; */
}


.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* 
html, body {
    width: 100%;
    height: 100%;
}
html, body, body > div {
height: 100%;
overflow: hidden;
} */

/* .overflow {
width: 100%;
height: 100%;
overflow: hidden;
} */
.overflow {
    /* display:grid; */
    align-items: center;
    justify-content: center;
}

.bg-navigation-dark {
    background: #11998e;  
    background: linear-gradient(to right, #38ef7d, #11998e); 

    /* max-width: 300px; */
    margin-top: 20px;
    border-radius: 40px;
    padding: 20px;
    position: fixed;
    bottom: 0;
    margin-bottom: 30px;
    z-index: 2;
}

.bg-navigation-light{
    /* background: #0f0c29;  
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29);  */

    background: #8E2DE2;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    margin-top: 20px;
    border-radius: 40px;
    padding: 20px;
    position: fixed;
    bottom: 0;
    margin-bottom: 30px;
    z-index: 2;
}
.navigation-image {
    width: 40px;
    cursor: pointer;
}
.navigation-imageMessage {
    width: 42px;
    -webkit-transform: translateY(8%);
            transform: translateY(8%);
    cursor: pointer;
}
.margin-navigation {
    margin-right: 10px;
}
/* .navigation-image2 {
    width: 20px;
    cursor: pointer;
} */
.navigation-image:hover {
    transition: 0.3s;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.navigation-imageMessage:hover {
    transition: 0.3s;
    -webkit-transform: translateY(8%) scale(1.1) ;
            transform: translateY(8%) scale(1.1) ;
}
.bg-bodyLight {
    /* background-color: #F5F5F5; */
    background-color: aliceblue;
    min-height:100vh;
    background-size:cover;
    color: black;
    font-family: 'Nunito', sans-serif;
    transition: 1s;
}
.bg-bodyDark {
    background-color: #232b2b;
    min-height:100vh;
    color: #FBFAF5;
    font-family: 'Nunito', sans-serif;
    transition: 1s;
}

/* ScrollBar */
/* width */
::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #232526;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 20px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}
::-webkit-scrollbar:horizontal {
    height: 0;
    width: 0;
    display: none;
}

::-webkit-scrollbar-thumb:horizontal {
    display: none;
}

/* Responsive */
@media(max-width: 576px) {
    .bg-navigation-dark, .bg-navigation-light 
    { max-width: 250px; padding: 15px; -webkit-transform: translateX(4%); transform: translateX(4%); }
    .navigation-image { width: 30px; }
    .navigation-imageMessage { width: 32px; -webkit-transform: translateY(4%); transform: translateY(4%); }
    .navigation-imageMessage:hover { -webkit-transform: translateY(4%) scale(1.1); transform: translateY(4%) scale(1.1);}
    .margin-navigation { margin-right:7px; }
    .overflow {
        overflow: visible;
    }
    ::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
    }
}
@media (min-width: 576px) and (max-width: 899px) { }
@media(min-width: 768px) { }
@media(min-width: 1024px) { }
@media(min-width: 1024px) and (height: 600px){ }
@media(min-width: 280px) and (height: 653px){
    .bg-navigation-dark, .bg-navigation-light 
    { -webkit-transform: translateX(3.4%); transform: translateX(3.4%); }
}


/* Baru */
/* html, body {
    width: 100%;
    height: 100%;
  }
html, body, body > div {
height: 100%;
} */

.hide{
    display: none;
}
.center {
    text-align: center;
}
.container-1 {
    padding-top: 90px;
}
.container-2 {
    margin-top: 90px;
}
.grid-header {
    text-align: center;
    font-size: 30px;
}
.grid-name {
    font-size: 23px; 
    text-align: justify;
    -webkit-transform: translate(10%, -100%);
            transform: translate(10%, -100%);
}
.color4 {
    color: #FC5957;
}
.avatar-image {
    width: 150px;
    height: 150px; 
    border-radius: 50%;
}
.text1 h4 {
    text-align: justify;
    font-weight: normal;
    font-family: 'Nunito', sans-serif;
}
.center1 {
    /* display:flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
}
.no-drag {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.fade-in {
	opacity: 1;
	-webkit-animation-name: fadeInOpacity;
	        animation-name: fadeInOpacity;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
	-webkit-animation-duration: 0.3s;
	        animation-duration: 0.3s;
}

@-webkit-keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@media(max-width: 899px) {

}
@media(max-width: 576px) {
    .grid-header h1{ font-size: 30px; }
    .grid-name { font-size: 16px; text-align: justify; -webkit-transform: translate(10%, -69%); transform: translate(10%, -69%); }
    .container-1 { padding-top: 50px; }
    .container-2 { margin-top: 20px; }
    .avatar-image { width: 100px; height: 100px; }
    .text1 h4{ font-size: 15px; }
    .overflow { overflow: hidden; }
}

@media (min-width: 576px) and (max-width: 899px) {
    .grid-header h1{ font-size: 50px; }
    .grid-name { font-size: 19px; text-align: justify; -webkit-transform: translate(10%, -46%); transform: translate(10%, -46%); }
    .container-1 { padding-top: 90px; }
    .container-2 { margin-top: 20px; }
    .avatar-image { width: 100px; height: 100px; }
    .text1 h4{ font-size: 15px; }
}
@media(min-width: 768px) {
    .grid-header h1{ font-size: 60px; }
    .grid-name { font-size: 23px; text-align: justify; -webkit-transform: translate(10%, -110%); transform: translate(10%, -110%); }
    .container-1 { padding-top: 90px; }
    .container-2 { margin-top: 20px; }
    .avatar-image { width: 170px; height: 170px; }
    .text1 h4{ font-size: 18px; }
    .overflow { overflow: hidden; }
}
@media(min-width: 1024px) {
    .grid-header h1{ font-size: 70px; }
    .grid-name { font-size: 28px; text-align: justify; -webkit-transform: translate(10%, -110%); transform: translate(10%, -110%); }
    .container-1 { padding-top: 120px; }
    .container-2 { margin-top: 40px; }
    .avatar-image { width: 180px; height: 180px;}
    .text1 h4{ font-size: 19px; }
    .overflow { overflow: hidden; }
}

@media(min-width: 1024px) and (height: 600px){
    .grid-header h1{ font-size: 70px; }
    .grid-name { font-size: 28px; text-align: justify; -webkit-transform: translate(10%, -110%); transform: translate(10%, -110%); }
    .container-1 { padding-top: 0px; }
    .container-2 { margin-top: 0px; }
    .avatar-image { width: 180px; height: 180px; }
    .text1 h4{ font-size: 19px; }
    .overflow { overflow: hidden; }
}
@media(min-width: 280px) and (height: 653px){
    .grid-header h1{ font-size: 27px; }
    .grid-name { font-size: 14px; text-align: justify; -webkit-transform: translate(10%, -67%); transform: translate(10%, -67%); }
    .container-1 { padding-top: 50px; }
    .container-2 { margin-top: 20px; }
    .avatar-image { width: 80px; height: 80px; }
    .text1 h4{ font-size: 10px;}
}
@media(min-width: 411px) and (height: 823px){
    .container-1 { padding-top: 150px; }
}
@media(min-width: 1253px) and (max-height: 651px) {
    .grid-header h1{ font-size: 70px; }
    .grid-name { font-size: 28px; text-align: justify; -webkit-transform: translate(10%, -110%); transform: translate(10%, -110%); }
    .container-1 { padding-top: 20px; }
    .container-2 { margin-top: 40px; }
    .avatar-image { width: 180px; height: 180px;}
    .text1 h4{ font-size: 19px; }
    .overflow { overflow: hidden; }
}
@media (max-height: 782px) {
    .container-1 {
        padding-top: 60px;
    }
    .container-2 {
        margin-top: 30px;
    }
    .overflow { overflow: hidden; }
}
@media (max-height: 680px) {
    .container-1 {
        padding-top: 0px;
    }
    .container-2 {
        margin-top: 30px;
    }
    .overflow { overflow: hidden; }
}
@media (max-height: 588px) {
    .container-1 {
        padding-top: 0px;
        margin-top: -10px;
    }
    .container-2 {
        margin-top: 0px;
    }
    .overflow { overflow: hidden; }
}
@media (max-height: 545px) {
    .container-1 {
        padding-top: 0px;
        margin-top: -40px;
    }
    .container-2 {
        margin-top: 0px;
    }
    .overflow { overflow: hidden; }
}
@media (max-height: 512px) {
    .container-1 {
        padding-top: 0px;
        margin-top: -40px;
    }
    .container-2 {
        margin-top: 0px;
        margin-top: -30px;
    }
    .overflow { overflow: hidden; }
}
@media (max-height: 485px) {
    .container-1 {
        padding-top: 0px;
        margin-top: -70px;
    }
    .container-2 {
        margin-top: 0px;
        margin-top: -30px;
    }
    .overflow { overflow: hidden; }
}

.container-1 {
    /* overflow: scroll; */
    padding-bottom: 40px;
}
.greet-profile {
    font-size: 30px;
    margin-top: -160px;
}
.text-profile {
    font-size: 18px; 
    padding-right: 50px;
    margin-top: -40px;
}
.image-profile {
    width: 160px;
    border-radius: 50%;
    margin-top: -5px;
    right: 0;
}
.rating-text {
    /* text-align: center; */
}
.rating-container1 {
    display: flex;
    align-items: center;
    margin-top: -20px;
    justify-content: center;
}
.rating-container2 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.margin-rating {
    margin-top: -44px;
}
.newest-projectButton {
    display: flex;
    align-items: center;
    justify-content: center;
}
.newest-projectText {
    text-align: center;
    font-size: 28px;
}
.hide {
    display: none;
}
/* Responsive */
@media(max-width: 576px) {
    .rating-text {
        text-align: center;
        font-size: 28px;
    }
    .container-1 {
        padding-bottom: 130px;
    }
    .newest-projectText {
        text-align: center;
        font-size: 28px;
    }
    .text-profile {
        padding-right: 0px;
    }
    .greet-profile {
        margin-top: -100px;
    }
    .overflow {
        overflow: scroll;
    }
}
@media (min-width: 576px) and (max-width: 899px) {  }
@media(min-width: 768px) { 
    /* .project-image { max-width: 200px; display: block; justify-content: center; margin: auto; }
    .judul-project { text-align: center; } */
 }
@media(max-width: 899px) { 
    .image-profile { 
        display: none;
    }
    .greet-profile {
        margin-top: -50px;
    } 
}
@media(min-width: 1024px) { }
@media(min-width: 1024px) and (height: 600px){ }
@media(min-width: 280px) and (height: 653px){ }
@media(min-width: 1253px) and (max-height: 651px) {
    .greet-profile {
        font-size: 30px;
        margin-top: -16px;
    }
    .container-1 {
        padding-bottom: 130px;
    }
    .overflow {
        overflow: scroll;
    }
}
@media (max-height: 910px) {
    .container-1 {
        padding-bottom: 130px;
    }
    .overflow {
        overflow: scroll;
    }
}
.message-container1 {
    /* padding-bottom: 120px; */
}
.message-container2 {
    text-align: center;
    padding-top: 60px;
}
.input-container {
    border-top-left-radius: 30px; 
    border-bottom-left-radius: 30px;
}
.contact-information {
    background-color: #166FAF;
    max-width: 350px;
    padding-left: 20px;
    border-radius: 30px;
    margin-top: -30px;
}
.color1 {
    background-color: aliceblue;
    color: black;
}
.color2 {
    background-color: #d1d1d1;
    color: aliceblue;
}


/* Responsive */
@media(max-width: 576px) {
    .input-container {
        border-top-left-radius: 30px; 
        border-bottom-left-radius: 0px;
        border-top-right-radius: 30px;
        border-radius: 30px;
    }
    .message-container1 {
        padding-bottom: 120px;
    }
    .message-container2 {
        padding-top: 0;
    }
    .input-padding {
        padding: 20px;
        padding-top: 0px;
    }
    .contact-information {
        background-color: #166FAF;
        max-width: 350px;
        padding-left: 20px;
        border-radius: 30px;
        margin-top: -30px;
    }
}
@media (min-width: 576px) and (max-width: 899px) { }
@media(min-width: 768px) { }
@media(min-width: 1024px) { }
@media(min-width: 1024px) and (height: 600px){
    .message-container1 {
        padding-bottom: 120px;
    } 
    .message-container2 {
        padding-top: 0px;
        margin-top: -20px;
    }
 }
@media(min-width: 540px) and (height: 720px){
    .contact-information {
        border-top-left-radius: 10px; 
        border-top-right-radius: 10px;
    }
}
@media(min-width: 280px) and (height: 653px){

}
@media(min-width: 1253px) and (max-height: 651px) {
    .message-container2 {
    text-align: center;
    padding-top: 0px;
    margin-top: -60px;
}
    .overflow {
        /* overflow: scroll; */
    }
}
.project-image {
    max-width: 200px;
    /* display: block;
    justify-content: center;
    margin: auto; */
}
.judul-project { }
/* .project-button { display: flex; justify-content: center; margin: auto; } */
  

/* Responsive */
@media(max-width: 576px) {
    .project-image { max-width: 200px; display: block; justify-content: center; margin: auto; }
    .judul-project { text-align: center; }
    .project-button { display: flex; justify-content: center; margin: auto; }
}
@media (min-width: 576px) and (max-width: 899px) { }
@media(min-width: 768px) { 
    /* .project-image { max-width: 200px; display: block; justify-content: center; margin: auto; }
    .judul-project { text-align: center; } */
 }
@media(min-width: 1024px) { }
@media(min-width: 1024px) and (height: 600px){ }
@media(min-width: 280px) and (height: 653px){ }
@media(min-width: 1253px) and (max-height: 651px) {
}
.image-experience1 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: auto; */
    max-width: 110px;
    margin-top: 15px;
}
.image-experience {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: auto; */
    max-width: 100px;
    margin-top: 5px;
}
.margin-keterangan {
    margin-top: 40px;
}
.border {
    border-bottom: 1px solid #C6C6C6;
}

/* Responsive */
@media(max-width: 576px) {
    .image-experience1 {
        margin: auto;
    }
    .text-center { text-align: center; }
    .image-experience {
        margin: auto;
    }
    .border {
        border-bottom: none;
    }
}
@media (min-width: 576px) and (max-width: 899px) { }
@media(min-width: 768px) { 
    /* .project-image { max-width: 200px; display: block; justify-content: center; margin: auto; }
    .judul-project { text-align: center; } */
 }
@media(min-width: 1024px) { }
@media(min-width: 1024px) and (height: 600px){ }
@media(min-width: 280px) and (height: 653px){ }
