.message-container1 {
    /* padding-bottom: 120px; */
}
.message-container2 {
    text-align: center;
    padding-top: 60px;
}
.input-container {
    border-top-left-radius: 30px; 
    border-bottom-left-radius: 30px;
}
.contact-information {
    background-color: #166FAF;
    max-width: 350px;
    padding-left: 20px;
    border-radius: 30px;
    margin-top: -30px;
}
.color1 {
    background-color: aliceblue;
    color: black;
}
.color2 {
    background-color: #d1d1d1;
    color: aliceblue;
}


/* Responsive */
@media(max-width: 576px) {
    .input-container {
        border-top-left-radius: 30px; 
        border-bottom-left-radius: 0px;
        border-top-right-radius: 30px;
        border-radius: 30px;
    }
    .message-container1 {
        padding-bottom: 120px;
    }
    .message-container2 {
        padding-top: 0;
    }
    .input-padding {
        padding: 20px;
        padding-top: 0px;
    }
    .contact-information {
        background-color: #166FAF;
        max-width: 350px;
        padding-left: 20px;
        border-radius: 30px;
        margin-top: -30px;
    }
}
@media (min-width: 576px) and (max-width: 899px) { }
@media(min-width: 768px) { }
@media(min-width: 1024px) { }
@media(min-width: 1024px) and (height: 600px){
    .message-container1 {
        padding-bottom: 120px;
    } 
    .message-container2 {
        padding-top: 0px;
        margin-top: -20px;
    }
 }
@media(min-width: 540px) and (height: 720px){
    .contact-information {
        border-top-left-radius: 10px; 
        border-top-right-radius: 10px;
    }
}
@media(min-width: 280px) and (height: 653px){

}
@media(min-width: 1253px) and (max-height: 651px) {
    .message-container2 {
    text-align: center;
    padding-top: 0px;
    margin-top: -60px;
}
    .overflow {
        /* overflow: scroll; */
    }
}