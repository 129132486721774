
/* Baru */
/* html, body {
    width: 100%;
    height: 100%;
  }
html, body, body > div {
height: 100%;
} */

.hide{
    display: none;
}
.center {
    text-align: center;
}
.container-1 {
    padding-top: 90px;
}
.container-2 {
    margin-top: 90px;
}
.grid-header {
    text-align: center;
    font-size: 30px;
}
.grid-name {
    font-size: 23px; 
    text-align: justify;
    transform: translate(10%, -100%);
}
.color4 {
    color: #FC5957;
}
.avatar-image {
    width: 150px;
    height: 150px; 
    border-radius: 50%;
}
.text1 h4 {
    text-align: justify;
    font-weight: normal;
    font-family: 'Nunito', sans-serif;
}
.center1 {
    /* display:flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
}
.no-drag {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@media(max-width: 899px) {

}
@media(max-width: 576px) {
    .grid-header h1{ font-size: 30px; }
    .grid-name { font-size: 16px; text-align: justify; transform: translate(10%, -69%); }
    .container-1 { padding-top: 50px; }
    .container-2 { margin-top: 20px; }
    .avatar-image { width: 100px; height: 100px; }
    .text1 h4{ font-size: 15px; }
    .overflow { overflow: hidden; }
}

@media (min-width: 576px) and (max-width: 899px) {
    .grid-header h1{ font-size: 50px; }
    .grid-name { font-size: 19px; text-align: justify; transform: translate(10%, -46%); }
    .container-1 { padding-top: 90px; }
    .container-2 { margin-top: 20px; }
    .avatar-image { width: 100px; height: 100px; }
    .text1 h4{ font-size: 15px; }
}
@media(min-width: 768px) {
    .grid-header h1{ font-size: 60px; }
    .grid-name { font-size: 23px; text-align: justify; transform: translate(10%, -110%); }
    .container-1 { padding-top: 90px; }
    .container-2 { margin-top: 20px; }
    .avatar-image { width: 170px; height: 170px; }
    .text1 h4{ font-size: 18px; }
    .overflow { overflow: hidden; }
}
@media(min-width: 1024px) {
    .grid-header h1{ font-size: 70px; }
    .grid-name { font-size: 28px; text-align: justify; transform: translate(10%, -110%); }
    .container-1 { padding-top: 120px; }
    .container-2 { margin-top: 40px; }
    .avatar-image { width: 180px; height: 180px;}
    .text1 h4{ font-size: 19px; }
    .overflow { overflow: hidden; }
}

@media(min-width: 1024px) and (height: 600px){
    .grid-header h1{ font-size: 70px; }
    .grid-name { font-size: 28px; text-align: justify; transform: translate(10%, -110%); }
    .container-1 { padding-top: 0px; }
    .container-2 { margin-top: 0px; }
    .avatar-image { width: 180px; height: 180px; }
    .text1 h4{ font-size: 19px; }
    .overflow { overflow: hidden; }
}
@media(min-width: 280px) and (height: 653px){
    .grid-header h1{ font-size: 27px; }
    .grid-name { font-size: 14px; text-align: justify; transform: translate(10%, -67%); }
    .container-1 { padding-top: 50px; }
    .container-2 { margin-top: 20px; }
    .avatar-image { width: 80px; height: 80px; }
    .text1 h4{ font-size: 10px;}
}
@media(min-width: 411px) and (height: 823px){
    .container-1 { padding-top: 150px; }
}
@media(min-width: 1253px) and (max-height: 651px) {
    .grid-header h1{ font-size: 70px; }
    .grid-name { font-size: 28px; text-align: justify; transform: translate(10%, -110%); }
    .container-1 { padding-top: 20px; }
    .container-2 { margin-top: 40px; }
    .avatar-image { width: 180px; height: 180px;}
    .text1 h4{ font-size: 19px; }
    .overflow { overflow: hidden; }
}
@media (max-height: 782px) {
    .container-1 {
        padding-top: 60px;
    }
    .container-2 {
        margin-top: 30px;
    }
    .overflow { overflow: hidden; }
}
@media (max-height: 680px) {
    .container-1 {
        padding-top: 0px;
    }
    .container-2 {
        margin-top: 30px;
    }
    .overflow { overflow: hidden; }
}
@media (max-height: 588px) {
    .container-1 {
        padding-top: 0px;
        margin-top: -10px;
    }
    .container-2 {
        margin-top: 0px;
    }
    .overflow { overflow: hidden; }
}
@media (max-height: 545px) {
    .container-1 {
        padding-top: 0px;
        margin-top: -40px;
    }
    .container-2 {
        margin-top: 0px;
    }
    .overflow { overflow: hidden; }
}
@media (max-height: 512px) {
    .container-1 {
        padding-top: 0px;
        margin-top: -40px;
    }
    .container-2 {
        margin-top: 0px;
        margin-top: -30px;
    }
    .overflow { overflow: hidden; }
}
@media (max-height: 485px) {
    .container-1 {
        padding-top: 0px;
        margin-top: -70px;
    }
    .container-2 {
        margin-top: 0px;
        margin-top: -30px;
    }
    .overflow { overflow: hidden; }
}
