.image-experience1 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: auto; */
    max-width: 110px;
    margin-top: 15px;
}
.image-experience {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: auto; */
    max-width: 100px;
    margin-top: 5px;
}
.margin-keterangan {
    margin-top: 40px;
}
.border {
    border-bottom: 1px solid #C6C6C6;
}

/* Responsive */
@media(max-width: 576px) {
    .image-experience1 {
        margin: auto;
    }
    .text-center { text-align: center; }
    .image-experience {
        margin: auto;
    }
    .border {
        border-bottom: none;
    }
}
@media (min-width: 576px) and (max-width: 899px) { }
@media(min-width: 768px) { 
    /* .project-image { max-width: 200px; display: block; justify-content: center; margin: auto; }
    .judul-project { text-align: center; } */
 }
@media(min-width: 1024px) { }
@media(min-width: 1024px) and (height: 600px){ }
@media(min-width: 280px) and (height: 653px){ }