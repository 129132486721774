
/* Baru */
html, body {
  width: 100%;
  height: 100%;
}
html, body, body > div {
  height: 100%;
  /* overflow: hidden; */
}
.overflow {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.bg-navigation {
  background: #11998e;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #38ef7d, #11998e);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  margin-top: 20px;
  border-radius: 40px;
  /* min-width: 320px;
  max-width: 320px; */
  padding: 20px;
  position: fixed;
  bottom: 0;
  margin-bottom: 30px;
  z-index: 2;
  
}

.bg-navigation-switch {
  background: #0f0c29;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-top: 20px;
  border-radius: 40px;
  /* min-width: 320px;
  max-width: 320px; */
  padding: 20px;
  position: fixed;
  bottom: 0;
  margin-bottom: 30px;
  z-index: 2;
}
.navigation-image {
  width: 40px;
  cursor: pointer;
}
.navigation-image2 {
  width: 20px;
  cursor: pointer;
}
.navigation-image:hover {
  transition: 0.3s;
  transform: scale(1.1);
}
.hide{
  display: none;
}
.bg-bodyLight {
  background-color: #F5F5F5;
  min-height:100vh;
  background-size:cover;
}
.bg-bodyDark {
  background-color: #232b2b;
 /* height: 97.94%; */
  min-height:100vh;
  /* background-size:cover; */
  /* width: 100%; */
  color: #FBFAF5;
  /* position: relative; */
}


.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
