.project-image {
    max-width: 200px;
    /* display: block;
    justify-content: center;
    margin: auto; */
}
.judul-project { }
/* .project-button { display: flex; justify-content: center; margin: auto; } */
  

/* Responsive */
@media(max-width: 576px) {
    .project-image { max-width: 200px; display: block; justify-content: center; margin: auto; }
    .judul-project { text-align: center; }
    .project-button { display: flex; justify-content: center; margin: auto; }
}
@media (min-width: 576px) and (max-width: 899px) { }
@media(min-width: 768px) { 
    /* .project-image { max-width: 200px; display: block; justify-content: center; margin: auto; }
    .judul-project { text-align: center; } */
 }
@media(min-width: 1024px) { }
@media(min-width: 1024px) and (height: 600px){ }
@media(min-width: 280px) and (height: 653px){ }
@media(min-width: 1253px) and (max-height: 651px) {
}